<template>
    <template-base width="95">
        <h1 class="card-header">Autentique-se para continuar</h1>
        <!--<LoginForm ref="login" />-->
        <div class="card-body">
            <form @submit.prevent="login">
                <div class="form-group">
                    <label for="userEmail">E-mail</label>
                    <input class="form-control tcs-input " id="userEmail" v-model="email" type="email" required
                        :disabled="isBusy" />
                </div>
                <div class="form-group">
                    <label for="userPassword">Senha</label>
                    <input class="form-control tcs-input " id="userPassword" v-model="password" type="password" required
                        :disabled="isBusy" />
                </div>
                <!--<hr class="my-4" />-->
                <vue-recaptcha v-if="captcha" sitekey="6LcQWL8dAAAAAMS38bZsYq0JYTQSoHxm5wkqUR0i" @verify="verifica" ref="recaptcha"  :loadRecaptchaScript="true"></vue-recaptcha>
                <div class="d-flex justify-content-between">
                    <router-link replace :to="{ name: 'esqueci_senha' }" class="align-self-center" data-cy="Esqueci minha senha">Esqueci minha senha</router-link>
                    <div class="text-right">
                        <button type="submit" class="btn btn-success lg" v-if="!isBusy">
                            Entrar
                        </button>
                        <button type="submit" class="btn btn-success lg" disabled="disabled" v-else>
                            <span class="spinner-border x-spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp;Entrando...
                        </button>
                    </div>
                </div>
                <transition name="fade">
                    <div class="d-flex mt-1 text-danger" v-if="error">
                        <x-circle-icon class="mr-2" />
                        <div>{{error}}</div>
                    </div>
                    <div class="d-flex mt-1 text-success" v-else-if="success">
                        <check-circle-icon class="mr-2" />
                        <div>Sucesso! Redirecionando...</div>
                    </div>
                </transition>
            </form>
        </div>
    </template-base>
</template>
<script>
import TemplateBase from '../templates/Base'
import loginService from '../services/login';
import { wait } from '../helpers/common';
import VueRecaptcha from 'vue-recaptcha';
import axios from 'axios';
import api from '../api';

export default {
    components: {
        TemplateBase,
        VueRecaptcha,
    },
    data () {
        return {
            email: '',
            password: '',
            isBusy: false,
            error: '',
            success: false,
            reCaptchaResponse: '',
            captcha: process.env.NODE_ENV === 'production',
        }
    },
    mounted() {
        if (this.$route.name === 'logout') {
            this.logout();
            this.$router.replace(this.$route.params.next || '/login');
        } else {
            this.validate();
        }
    },
    methods: {
        verifica(token) {
            this.reCaptchaResponse = token;
        },
        async login () {
            this.isBusy = true;
            this.error = '';
            this.success = false;
            try {
                if (!this.reCaptchaResponse && this.captcha) throw new Error('Por favor preencha o captcha.');
                let sucesso = await loginService.login(this.email, this.password, this.reCaptchaResponse);
                if (sucesso) {
                    this.success = true;
                    await wait(1000);
                    this.$router.replace(this.$route.params.next || '/');
                    this.isBusy = false;
                    this.email = '',
                    this.password = '',
                    this.success = false;
                } else {
                    throw new Error('E-mail ou senha inválidos');
                }
            } catch (e) {
                if (this.captcha) this.$refs.recaptcha.reset();
                this.reCaptchaResponse = '';
                this.isBusy = false;
                this.error = e.response?.data?.error || e.toString() || 'Erro desconhecido, por favor tente novamente.';
            }
        },
        logout () {
            axios.post(api.v1.logs.logout());
            loginService.logout();
        },
        validate () {
            this.isBusy = true;
            if(loginService.isLoggedIn()) {
                this.success = true;
                this.$router.replace(this.$route.params.next || '/');
            } else {
                this.isBusy = false;
            }
        }
    },
};
</script>
<style scoped>
.spinner-border.x-spinner-border-sm {
    width: 1.1rem;
    height: 1.1rem;
    border-width: 0.2em;
}

@media screen and (min-width: 790px) {
    .card {
        max-width: 55%
    }
}

</style>
